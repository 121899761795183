import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from "@chakra-ui/react";
import { Box, HStack, Heading, Stack, Text } from "@chakra-ui/layout";
import React from "react";

const Cards = () => {
  return (
    <div style={{ margin: "3rem 0" }}>
      <Stack maxW='100%'  gap='4rem' justifyContent='center'>
        <HStack w='fit-content' m='auto' flexWrap='wrap' gap='4rem'>
          <Card h='255px' border='solid 1px #ddd' w='100%'>
            <CardHeader>
              <Heading size='md'>Refresh Center</Heading>
            </CardHeader>
            <CardBody>
              <Text fontWeight='500'>
               Mantente conectado a tu último puntaje MyCreditScore
              </Text>
            </CardBody>
            <CardFooter>
              <Button color='#fff' bg='orange'>
              ACTUALIZAR AHORA
              </Button>
            </CardFooter>
          </Card>

          <Card  border='solid 1px #ddd' w='100%'>
            <CardHeader>
              <Heading size='md'> Resumen de Crédito</Heading>
            </CardHeader>
            <CardBody>
              <Text>Obtén una vista consolidada de todas tus cuentas de préstamo.</Text>
              <Text>
               tarjetas de crédito, consultas de financieras y tu historial de pagos.
              </Text>
              <Text>todo en un solo lugar.</Text>
            </CardBody>
            <CardFooter>
              <Button color='#fff' bg='orange'>
               Saber más
              </Button>
            </CardFooter>
          </Card>
        </HStack>

        <Box minW='77%' m='auto'>
          <Card  border='solid 1px #ddd'>
            <CardHeader>
              <Heading size='md'>Historial de Puntaje</Heading>
            </CardHeader>
            <CardBody>
              <Text>
              Descubre cómo ha cambiado tu puntaje MyCreditScore a lo largo del tiempo.
              </Text>
            </CardBody>
            <CardFooter>
              <Button color='#fff' bg='orange'>
              Saber más
              </Button>
            </CardFooter>
          </Card>
        </Box>
      </Stack>
    </div>
  );
};

export default Cards;
