import React, { useState } from 'react';
import { Container, Heading, Text, VStack, Box, FormControl, FormLabel, Input, Textarea, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ContactPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post('https://2e3fzfc1u8.execute-api.us-east-1.amazonaws.com/Prod/sendemail', {
        recipient: formData.email,
        subject: 'Contáctenos : ',
        message: formData.message,
        attachment: '', // Si necesitas adjuntar un archivo, convertirlo a base64 aquí
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setResponseMessage('Mensaje enviado con éxito');
    } catch (error) {
      setResponseMessage('Error al enviar el mensaje');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="start">
        <Heading as="h1" size="lg" mb={6}>
          Contacto
        </Heading>
        <Text fontSize="md" mb={6}>
          Si tienes alguna pregunta o inquietud, por favor, completa el siguiente formulario y nos pondremos en contacto contigo lo antes posible.
        </Text>
        <Box as="form" onSubmit={handleSubmit} width="full">
          <FormControl id="name" mb={4} isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input 
              type="text" 
              name="name" 
              placeholder="Tu nombre" 
              value={formData.name} 
              onChange={handleChange} 
            />
          </FormControl>
          <FormControl id="email" mb={4} isRequired>
            <FormLabel>Correo Electrónico</FormLabel>
            <Input 
              type="email" 
              name="email" 
              placeholder="Tu correo electrónico" 
              value={formData.email} 
              onChange={handleChange} 
            />
          </FormControl>
          <FormControl id="message" mb={4} isRequired>
            <FormLabel>Mensaje</FormLabel>
            <Textarea 
              name="message" 
              placeholder="Escribe tu mensaje aquí" 
              value={formData.message} 
              onChange={handleChange} 
            />
          </FormControl>
          <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
            Enviar
          </Button>
        </Box>
        {responseMessage && <Text mt={4}>{responseMessage}</Text>}
    
      </VStack>
    </Container>
  );
};

export default ContactPage;
