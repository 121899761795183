import React from 'react'
import "../CSS/footer.css"
import FB from "../images/socialLinks/facebook-icon.svg"
import Twitter from "../images/socialLinks/twitter-icon.svg"
import YT from "../images/socialLinks/youtube-icon.svg"
import Linkedin from "../images/socialLinks/linkedin-icon.svg"
import icon from "../images/socialLinks/ifg-lockup-yellow-grey.svg"
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

export const Footer = () => {

  return (
    <div className='footer' >
        <div className='left-side'>
            <div className='info'>
                <div className="text">
                <h2><Link to="/about">Sobre Nosotros</Link></h2>
                <hr />
                <p><Link to="/contact">Contacto</Link></p>
                <p><Link to="/about">Sobre Nosotros</Link></p>
                </div>
                <div className="text">
                <h2><Link to="/helpcenter">Información</Link></h2>
                <hr />
                <p><Link to="/privacy-policy">Políticas de Privacidad</Link></p>
                <p><Link to="/terms-and-conditions">Términos y Condiciones</Link></p>
                <p>Operaciones Especificadas</p>
                </div>
                <div className="text">
                <h2><Link to="/about">Más Información</Link></h2>
                <hr />
                <p><Link to="/helpcenter">Consulta a los Expertos</Link></p>
                <p>Suscripción Pagada</p>
               </div>
            </div>
            <p className='policy'>Política de Privacidad | Términos de Uso  | Mapa del Sitio</p>
        </div>
        <div className='right-side'>
            <div>
            <h2>¿Tienes preguntas?</h2>
            <Link to="/contact">
            <Button 
                colorScheme="blue" 
                borderRadius="full" // Bordes completamente redondeados
                padding="8px 16px" // Ajusta el tamaño del botón
                fontSize="md" // Ajusta el tamaño del texto
                _hover={{ bg: 'blue.600' }} // Cambia el color de fondo al pasar el mouse
                _active={{ bg: 'blue.700' }} // Cambia el color de fondo al hacer clic
                _focus={{ boxShadow: 'outline' }} // Agrega un sombreado al enfocar
                >
                Contáctanos
            </Button>
            </Link>
            </div>
            <div className='social-images'>
                <div className="img">
                    <a href="https://www.facebook.com/">
                    <img src={FB} alt="not found" />
                    </a>
                </div>
                <div className="img">
                    <a href="https://twitter.com/">
                    <img src={Twitter} alt="not found" />
                    </a>
                </div>
                <div className="img">
                    <a href="https://www.linkedin.com/">
                    <img src={Linkedin} alt="not found" />
                    </a>
                </div>
            </div>
            <div className='icon'>
                <img src="../images/logo.png" alt="" />
            </div>
            <div className='copy-right'>
               <p>© Copyright 2023 MyCreditScore. Todos los derechos reservados.</p>
            </div>
        </div>
    </div>
  )
}
