import React from 'react'
import { About } from './About'
import SupportForm from './SupportForm'

export const HelpCenter = () => {
  return (
    <div>
      <SupportForm/>
    </div>
  )
}
