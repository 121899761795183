import React from 'react';
import { Container, Heading, Text, Box, VStack, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="start">
        <Button
          onClick={() => navigate(-1)} // Volver a la página anterior
          colorScheme="blue"
          variant="outline"
          mb={4}
        >
          Volver
        </Button>
        <Heading as="h1" size="lg" mb={6}>
          Términos y Condiciones
        </Heading>
        <Text fontSize="md" lineHeight="tall">
          Bienvenido a nuestra página de Términos y Condiciones. Aquí detallamos los aspectos legales que regulan el uso de nuestras fuentes de información proporcionadas por terceros.
        </Text>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Fuentes de Información de Terceros
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Respecto a las fuentes de información de terceros, Equifax no es responsable de esas bases de datos, incluyendo:
          </Text>
          <Text fontSize="sm" lineHeight="base">
            <ul>
              <li>Boletín Comercial: Administrado por la Cámara de Comercio de Santiago A.G.</li>
              <li>Morosidad en Sistemas Financiero/Comercial – INFOCOM: También administrado por la Cámara de Comercio de Santiago A.G.</li>
              <li>Boletín Laboral: Una base de datos administrada por Equifax en calidad de mandatario de la Dirección del Trabajo.</li>
            </ul>
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Limitaciones de Responsabilidad
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Al hacer uso de esta información, usted acepta que la responsabilidad por el contenido y la veracidad de los datos pertenece exclusivamente a las entidades que gestionan estas bases.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Aceptación de Términos
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Al continuar utilizando los servicios ofrecidos, usted acepta estar vinculado por estos términos, los cuales pueden ser actualizados periódicamente sin previo aviso.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Contacto
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Si tiene preguntas o necesita más información sobre nuestros términos y condiciones, no dude en contactarnos contacto@mycreditscore.cl .
          </Text>
        </Box>
      </VStack>
    </Container>
  );
}

export default TermsAndConditions;
