import React from 'react';
import { Container, Heading, Text, Box, VStack, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="start">
        <Button
          onClick={() => navigate(-1)} // Volver a la página anterior
          colorScheme="blue"
          variant="outline"
          mb={4}
        >
          Volver
        </Button>
        <Heading as="h1" size="lg" mb={6}>
          Política de Privacidad
        </Heading>
        <Text fontSize="md" lineHeight="tall">
          En MyCreditScore, valoramos y protegemos la privacidad de nuestros usuarios. Esta política describe cómo recopilamos, utilizamos y protegemos tu información personal cuando visitas nuestro sitio web.
        </Text>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Recopilación de Información
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Recopilamos información personal como nombre, dirección de correo electrónico y detalles financieros cuando interactúas con nuestros servicios. Esta información se recopila para proporcionarte un servicio personalizado y eficiente.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Compartir Información
          </Text>
          <Text fontSize="sm" lineHeight="base">
            No compartimos tu información personal con terceros, excepto en casos donde sea necesario para cumplir con una obligación legal o para ofrecerte un servicio solicitado.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Seguridad de la Información
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Implementamos medidas de seguridad avanzadas para proteger tu información contra accesos no autorizados, pérdidas o alteraciones.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Derechos del Usuario
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Tienes derecho a acceder, corregir o eliminar tu información personal en cualquier momento. También puedes optar por no recibir comunicaciones de marketing de nuestra parte.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Cambios en la Política
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Podemos actualizar esta política de privacidad ocasionalmente. Te notificaremos sobre cualquier cambio significativo a través de nuestro sitio web o por correo electrónico.
          </Text>
        </Box>
        <Box>
          <Text fontSize="md" fontWeight="semibold" mb={4}>
            Contacto
          </Text>
          <Text fontSize="sm" lineHeight="base">
            Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, puedes contactarnos a través de contacto@mycreditscore.cl.
          </Text>
        </Box>
      </VStack>
    </Container>
  );
}

export default PrivacyPolicy;
