import React from 'react';
import { Box, Text, Flex, Button, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import ConstructionImg from '../images/construction-img.jpeg'; // Asegúrate de tener una imagen en esta ruta

const UnderConstruction = () => {

  const navigate = useNavigate(); // Hook para la navegación

  const handleGoBack = () => {
    navigate(-1); // Navega a la página anterior
  };
  
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      height="100vh"
      bg="#f4f4f4"
      p={4}
    >
      <Image 
        src={ConstructionImg} 
        alt="En Construcción" 
        boxSize="150px" 
        objectFit="cover"
        mb={4}
      />
      <Box textAlign="center">
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          ¡Estamos en Construcción!
        </Text>
        <Text fontSize="lg" color="gray.600" mb={4}>
          Estamos trabajando arduamente para estar en línea y poder atender tus requerimientos. ¡Gracias por tu paciencia!
        </Text>
        <Button colorScheme="blue" size="lg" onClick={handleGoBack}>
          Volver
        </Button>
      </Box>
    </Flex>
  );
};

export default UnderConstruction;
