import React from 'react';
import { Container, Heading, Text, VStack, Box, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const MissionVisionValues = () => {
  const navigate = useNavigate();

  return (
    <Container maxW="container.md" py={8}>
      <VStack spacing={6} align="start">
        <Button
          onClick={() => navigate(-1)} // Volver a la página anterior
          colorScheme="blue"
          variant="outline"
          mb={4}
        >
          Volver
        </Button>
        <Heading as="h1" size="xl" color="blue.800" mb={6}>
          Quienes Somos
        </Heading>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Introducción
          </Text>
          <Text fontSize="sm" lineHeight="tall">
            Hace dos meses, comenzamos nuestra historia en Santiago, Chile, como un nuevo proyecto con un propósito claro: comprender mejor a nuestros clientes y fortalecer nuestras relaciones con ellos. La idea nació como parte de un Proyecto de Título en la Universidad de Santiago, en respuesta a una necesidad de ESSERP, una empresa B2B.
          </Text>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Misión
          </Text>
          <Text fontSize="sm" lineHeight="tall">
            Nuestro compromiso es ofrecer información precisa y actualizada, convirtiéndonos en un recurso confiable para nuestros clientes. Queremos que confíen en la información que proporcionamos, ayudándolos a impulsar sus estrategias comerciales y tomar decisiones acertadas.
          </Text>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Valores
          </Text>
          <Text fontSize="sm" lineHeight="tall">
            Nos guiamos por la precisión, la transparencia, y el compromiso con nuestros clientes. Creemos en la importancia de ofrecer datos actualizados y confiables para que nuestros clientes puedan estar un paso adelante en un mundo empresarial en constante cambio.
          </Text>
        </Box>
        <Box>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Visión
          </Text>
          <Text fontSize="sm" lineHeight="tall">
            Aspiramos a ser líderes en la provisión de información valiosa para el desarrollo estratégico de las empresas, construyendo relaciones sólidas y de confianza con nuestros clientes en el mercado B2B.
          </Text>
        </Box>
      </VStack>
    </Container>
  );
}

export default MissionVisionValues;
