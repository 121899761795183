
// product 
// export const PRODUCT_REQUEST= "PRODUCT_REQUEST";
// export const PRODUCT_FAILURE= "PRODUCT_FAILURE";

// export const GET_PRODUCT_SUCCESS= "GET_PRODUCT_SUCCESS";
// export const POST_PRODUCT_SUCCESS= "POST_PRODUCT_SUCCESS";
// export const DELETE_PRODUCT_SUCCESS= "DELETE_PRODUCT_SUCCESS";
// export const PATCH_PRODUCT_SUCCESS= "PATCH_PRODUCT_SUCCESS";


// LOGIN 
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// LOGOUT
export const LOGOUT= "LOGOUT";

// SignUP

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";