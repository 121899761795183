import React, { useState } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import signup_banner from '../images/signup-banner.svg'


const SignUp = () => {
  const [SignUpStatus, setSignUpStatus] = useState(false);
  const [formData, setFormData] = useState({
    rut: '',
    nombre: '',
    apellido: '',
    email: '',
    nrodocumento: '123 123 123', // Se establece por defecto
    fono: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    axios.post('https://5st0ssammg.execute-api.us-east-1.amazonaws.com/Prod/mycreditscore/api/register', formData)
      .then((response) => {
        setSignUpStatus(true);
      })
      .catch((error) => {
        console.error('El registro falló:', error.response ? error.response.data : error.message);
      });
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center', gap:'2rem', marginTop: '3rem', flexWrap: 'wrap'}}>
      <div>
        <img className='signup-img' style={{width: '350px', margin: '3rem', opacity: '0.9'}} src={signup_banner} alt="" />
      </div>
      <DIV>
        {SignUpStatus ? <h3>Registro Exitoso</h3> : <h2>Página de Registro</h2>}
        <form onSubmit={handleSubmit}>
          <div className='boxx'>
            <input type="text" name="nombre" placeholder='Nombre' value={formData.nombre} onChange={handleChange} required />
          </div>
          <div>
            <input type="text" name="apellido" placeholder='Apellido' value={formData.apellido} onChange={handleChange} required />
          </div>
          <div>
            <input type="email" name="email" placeholder='Correo' value={formData.email} onChange={handleChange} required />
          </div>
          <div>
            <input type="text" name="rut" placeholder='RUT' value={formData.rut} onChange={handleChange} required />
          </div>
          <div>
            <input type="text" name="fono" placeholder='Celular' value={formData.fono} onChange={handleChange} required />
          </div>
          <button type="submit">Enviar</button>
        </form>
        <Link to='/login'><p>¿Ya tienes una cuenta? <span style={{color:'#24ADF3'}}>Inicia sesión aquí</span></p></Link>
      </DIV>
    </div>
  );
};

export default SignUp;

const DIV = styled.div`
  width: 400px;
  color: #333;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  align-items: center;

  input {
    width: 100%;
    margin-bottom: 10px;
    height: 30px;
    font-size: larger;
    border: solid 1px #ccc;
    padding: 20px 15px;
    border-radius: 6px;
    color: #333;
  }
  button {
    width: 100%;
    padding: 6px;
    border-radius: 6px;
    color: #fff;
    background-color: #32A9ED;
    font-size: 18px;
    font-weight: 300;
    border: none;
  }
  h3 {
    color: #00bc00;
    font-weight: 500;
    font-size: 24px;
  }
`;
