import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const CibilScore = () => {
  return (
    <div>
      <Box p='1rem' m='2rem auto' w='90%'>
        <HStack borderRadius='6px' border='solid 1px #ddd' p='1.5rem' justifyContent='space-between' flexWrap='wrap'>
          <Box>
            <Text as='p' fontWeight='bold'>
            Eres elegible para obtener puntajes y reportes de MyCreditScore ilimitados.
            </Text>
            <Text as='p'>
            Monitorea tu crédito y mejora tu elegibilidad para préstamos.
            </Text>
          </Box>
          <Button m='0 2rem' fontWeight='500' color='#fff' bg='#3DAEEE'>
          OBTÉN ACCESO ILIMITADO AHORA
          </Button>
        </HStack>
      </Box>

      <HStack
        borderRadius='6px'
        border='solid 1px #ddd'
        p='10px'
        w='87%'
        m='1rem auto'
        gap='1rem'
        alignItems='flex-start'
        justifyContent='space-between'
        flexWrap='wrap'
        // flex-direction='column'
      >
        <Box   borderBottom='1px solid #ccc' borderRight={"solid 1.5px #ddd"} p={"1rem"} pr='4rem'>
          <Text m='10px' as='h2' fontSize='20px'>MyCredit Score</Text>
          <CircularProgress color='#B9CD5D' m='5px' value={77.4} size='150px'>
            <CircularProgressLabel color='#B9CD5D'>774</CircularProgressLabel>
          </CircularProgress>
          <Box p='10px'>
            <HStack mb='1rem'>
              <Link style={{fontSize:'14px'}}>Ver mi análisis de puntaje</Link>
              <Link style={{fontSize:'14px'}}>Consulta tu Informe CIBIL Gratis</Link>
            </HStack>
            <HStack>
              <Link style={{ color: "#0078D4", fontSize: "12px" }}>
              Mira este video
              </Link>
              <Text color={"#0078D4"} fontSize='12px' as='p'>
               saber más sobre el puntaje MyCreditScore.
              </Text>
            </HStack>
          </Box>
        </Box>

        <Box p='10px' w='400px' display='flex' flexDirection={"column"} margin='auto' gap={"20px"}>
          <Text as='h2'>Where You Stand</Text>
          <Text fontSize='14px' as='p'>
          Consulta cómo tu puntaje MyCreditScore se compara con el de otros consumidores que se auto-monitorean.
          </Text>
          <Button p={"10px 20px"} w='fit-content' fontWeight='500' color='#fff' bg={"#3DAEEE"}>
           SABER MÁS
          </Button>
        </Box>
      </HStack>
    </div>
  );
};

export default CibilScore;
