import React, { useState } from 'react';
import axios from 'axios';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    issueType: '',
    description: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post('https://2e3fzfc1u8.execute-api.us-east-1.amazonaws.com/Prod/sendemail', {
        recipient: formData.email,
        subject: `Nuevo mensaje desde Soporte: ${formData.issueType}`,
        message: formData.description,
        attachment: '', // Si necesitas adjuntar un archivo, convertirlo a base64 aquí
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setResponseMessage('Mensaje enviado con éxito');
    } catch (error) {
      setResponseMessage('Error al enviar el mensaje');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Formulario de Soporte</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name" style={styles.label}>Nombre:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          style={styles.input}
        />

        <label htmlFor="email" style={styles.label}>Correo Electrónico:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          style={styles.input}
        />

        <label htmlFor="issueType" style={styles.label}>Tipo de Problema o Consulta:</label>
        <select
          id="issueType"
          name="issueType"
          value={formData.issueType}
          onChange={handleChange}
          required
          style={styles.select}
        >
          <option value="">Seleccione un tipo de problema</option>
          <option value="Problema Técnico">Problema Técnico</option>
          <option value="Problema con la Cuenta">Problema con la Cuenta</option>
          <option value="Facturación">Facturación</option>
          <option value="Solicitud de Funcionalidad">Solicitud de Funcionalidad</option>
          <option value="Consulta General">Consulta General</option>
          <option value="Otros">Otros</option>
        </select>

        <label htmlFor="description" style={styles.label}>Descripción del Problema o Consulta:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          placeholder="Describe brevemente el problema o consulta..."
          required
          style={styles.textarea}
        />

        <button type="submit" style={styles.button} disabled={isSubmitting}>
          {isSubmitting ? 'Enviando...' : 'Enviar'}
        </button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

const styles = {
  container: {
    width: '50%',
    margin: '50px auto',
    background: '#fff',
    padding: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  title: {
    textAlign: 'center',
    color: '#333',
  },
  label: {
    display: 'block',
    margin: '15px 0 5px',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '5px 0 15px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  select: {
    width: '100%',
    padding: '10px',
    margin: '5px 0 15px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    margin: '5px 0 15px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    resize: 'vertical',
    minHeight: '100px',
  },
  button: {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};

export default SupportForm;
