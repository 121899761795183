import React from 'react'
import CreditInfo from '../components/CreditInfo'
import { Sidebar } from '../components/Sidebar'

const Accounts = () => {
  return (
    <div style={{
      textAlign: 'center',
    }}>

    <CreditInfo />
    </div>
  )
}

export default Accounts