import React from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de tener esta importación
import { Text, Box, Flex } from '@chakra-ui/react'; // Importa componentes de Chakra UI
import { VStack } from '@chakra-ui/react';
import cityImg from "../images/aboutUS.png";
import "../CSS/about.css";
import { Footer } from '../components/Footer';
import banner from '../images/banner.svg';

export const About = () => {
  return (
    <div>
      <div className="about-top">
        <div className='about-text'>
          <h3>MycreditScore</h3>
          <h2>Saber Más</h2>
          <p>Somos la empresa líder en información crediticia en Chile, con una de las mayores bases de datos de información de consumidores..</p>
          <button>Saber Más</button>
        </div>
        <div className='img'>
          <img style={{ width: '600px', margin: '1rem auto' }} src={banner} alt="Banner" />
        </div>
      </div>
      <div className="about-middle">
        <div className='middle-left'>
          <h2>Enlaces</h2>
           
<VStack spacing={2} align="start" paddingLeft={4}>
  <Link to="/under-construction">
    <Text color="blue.500" _hover={{ textDecoration: 'underline' }}>Servicios</Text>
  </Link>
  <Link to="/under-construction">
    <Text color="blue.500" _hover={{ textDecoration: 'underline' }}>Historia de la Compañía</Text>
  </Link>
  <Link to="/mission-vision-values">
      <Text color="blue.500" _hover={{ textDecoration: 'underline' }}>
        Misión, Visión y Valores
      </Text>
    </Link>
  <Link to="/privacy-policy">
  <Text color="blue.500" _hover={{ textDecoration: 'underline' }}>Política de Privacidad</Text>
</Link>
<Link to="/contact">
  <Text color="blue.500" _hover={{ textDecoration: 'underline' }}>Contáctanos</Text>
</Link>

</VStack>

        </div>
        <div className='middle-right'>
          <p>
            MyCreditScore es la empresa pionera en información y análisis de crédito en Chile, proporcionando soluciones avanzadas para la evaluación del riesgo crediticio. 
            Con una vasta base de datos y herramientas analíticas, MyCreditScore permite a las instituciones financieras tomar decisiones informadas, mejorar el acceso al 
            crédito y gestionar el riesgo de manera eficiente. Su enfoque innovador y su compromiso con la precisión y la transparencia han posicionado a MyCreditScore como 
            líder en el mercado de la información crediticia en el país. 
          </p>
        </div>
      </div>
      <hr style={{ backgroundColor: '#0094e9c2', height: "2px" }} />
      <Footer />
    </div>
  );
};
