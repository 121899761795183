import React from 'react'
import { About } from './About'

export const CreditReport = () => {
  return (
    <div>
      <About/>
    </div>
  )
}
